<template>
  <v-app>
    <router-view />
    <!--     <v-speed-dial
      v-if="currentUser"
      v-model="fab"
      right
      bottom
      absolute
      style="bottom: 80px"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="pink" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-account </v-icon>
        </v-btn>
      </template>

      <v-btn fab dark color="green" @click="signOut()"
        ><v-icon>mdi-logout-variant</v-icon></v-btn
      >
      <v-btn fab dark color="green"><v-icon>mdi-account-plus</v-icon></v-btn>
    </v-speed-dial> -->
  </v-app>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
export default {
  name: "App",
  components: {},
  data() {
    return {
      currentUser: null,
      fab: null,
    };
  },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.currentUser = user;
    });
  },
  methods: {
    signOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.currentUser = null;
          this.$router.push({ name: "Login" });
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // An error happened.
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
