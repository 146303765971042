// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzX5EZl_unvzBimwBO9h3-JQ38-v0X6hc",
  authDomain: "craving-339012.firebaseapp.com",
  projectId: "craving-339012",
  storageBucket: "craving-339012.appspot.com",
  messagingSenderId: "422245315126",
  appId: "1:422245315126:web:c47851b572694eaf69de97",
  measurementId: "G-TVK7D2GHQH",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore();

export default db;
