<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-btn class="ma-2" color="pink" dark x-large @click.stop="googleSignIn()"
        >Sign in with google</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
export default {
  name: "Login",
  methods: {
    async googleSignIn() {
      const auth = getAuth();

      signInWithPopup(auth, new GoogleAuthProvider())
        .then(() => {
          this.$router.replace("map");
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
