<template>
  <MapOverview />
</template>

<script>
// @ is an alias to /src
import MapOverview from "@/components/MapOverview.vue";

export default {
  name: "Home",
  components: {
    MapOverview,
  },
};
</script>
