import Vue from "vue";
import App from "./App.vue";
import * as VueGoogleMaps from "vue2-google-maps";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { getAuth, onAuthStateChanged } from "firebase/auth";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAzX5EZl_unvzBimwBO9h3-JQ38-v0X6hc",
    libraries: "places", // necessary for places input
  },
});

let app;
const auth = getAuth();
// eslint-disable-next-line no-unused-vars
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
